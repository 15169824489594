<template>
  <!-- 委员会 -->
  <div class="privacyPolicy">

    <Head />
    <div class="main">
      <div>
        <div class="top-title">

          <h3>Commission ratio</h3>
          <p> {{ $fanyi('随着你的购买量的增加，你必须支付的佣金率将逐渐降低。') }}</p>
          <p style="margin-top: 10px; margin-bottom: 10px;">{{ $fanyi('我们将根据订单中货物的价值收取佣金。') }}</p>
          <p style="margin-bottom: 20px;"> {{ $fanyi('佣金率是根据您购买的商品的累计价值确定的。（货物价值=数量*单价）') }}</p>


          <p style="margin-bottom:20px;">{{ $fanyi('例如，如果您购买的商品的累计价值超过50000美元，您未来订单的佣金将变为7%。') }} </p>
          <p class="txt">{{ $fanyi('请参阅下表，查看您购买商品的累计价值所对应的百分比：') }}</p>
        </div>
        <!-- list -->
        <div class="list">

          <div class="header">
            <span style="width: 200px;">Membership Level</span>
            <span>Commission Fee Rate</span>
            <span>Value of Purchase (USD)</span>
          </div>
          <div> <span><img src="../../assets/jingtaiye-pic/shouxufei/commission4.svg" alt=""></span>
            <span>4%</span>
            <span>+${{ $fun.EURNum(40000) }}</span>
          </div>
          <div class="huise"><span><img src="../../assets/jingtaiye-pic/shouxufei/commission3.svg" alt=""></span>
            <span>5%</span>
            <span>+${{ $fun.EURNum(30000) }}</span>
          </div>
          <div><span><img src="../../assets/jingtaiye-pic/shouxufei/commission2.svg" alt=""></span>
            <span>6%</span>
            <span>+${{ $fun.EURNum(20000) }}</span>
          </div>
          <div class="huise"><span><img src="../../assets/jingtaiye-pic/shouxufei/commission1.svg" alt=""></span>
            <span>7%</span>
            <span>+${{ $fun.EURNum(10000) }}</span>
          </div>
          <div><span><img src="../../assets/jingtaiye-pic/shouxufei/commission0.svg" alt=""></span>
            <span>8%</span>
            <span>+${{ $fun.EURNum(0) }}</span>
          </div>



        </div>

        <div class="footer-text">
          <h3>Fair commissions and great value</h3>
          <p>Rakumart will provide you with more services at a lower price. With the your membership levels up, you can
            enjoy a lower commission rate and hence reduce more costs.</p>


        </div>
      </div>

      <!-- 底部区域 -->
      <div class="footer-box">
        <div class="img">
          <img src="../../assets/jingtaiye-pic/sheyingfuwu/contact.svg" />
        </div>
        <h3>If you have any questions, please contact an agent.</h3>
        <p>Are you looking for the best solution for your Chinese imports? The answer is: Rakumart. We are professional
          agents who will take care of everything for you from purchasing the goods to delivering them to your doorstep.
          Contact us and let our advantages work for you.</p>
        <div class="bottom" @click="chat">Contact us</div>
      </div>

    </div>
    <Dialog ref="dialog" :config="config" />
    <Foot />
  </div>
</template>
<script>
import Foot from "../../components/foot/Foot.vue";
import Head from "../../components/head/HomePageTop.vue";
import Dialog from '../../components/public/Dialog.vue'
export default {
  name: 'Committee',
  data() {

    return {
      config: {
        top: '20vh',
        width: '500px',
        title: this.$fanyi('是否登录'),
        btnTxt: [this.$fanyi('前往登录'), this.$fanyi('关闭')],
      },
    };
  },
  components: {
    Foot,
    Head,
    Dialog
  },
  methods: {
    chat(e) {
      let element = document.getElementById("zsiq_float");
      // 创建事件

      var event = document.createEvent("MouseEvents");
      // 定义事件 参数： type, bubbles, cancelable
      event.initEvent("click", true, true);
      // 触发对象可以是任何元素或其他事件目标
      element.dispatchEvent(event);

      // if (this.$store.state.userInfo) {

      // } else {
      //   this.$refs.dialog.open(
      //     this.$fanyi("是否前往登录?"),
      //     () => {
      //       this.$store.commit("getactivePage", this.$route.fullPath);
      //       this.$fun.toPage("/login");
      //     },
      //     () => { }
      //   );
      // }
    },
  },
};
</script>
<style lang='scss' scoped>
.privacyPolicy {
  background-color: #fff;
}

.main {

  margin: auto;
  margin-top: -25px;

  padding-bottom: 81px;

  >div {
    width: 1000px;
    margin: auto;
  }

  .top-title {
    width: 1000px;
    margin: auto;
    padding-top: 45px;

    h3 {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
      font-size: 24px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #ff730b;
      line-height: 32px;
    }

    p {
      margin: auto;
      width: 800px;
      text-align: center;
      font-size: 16px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #222222;
      line-height: 24px;
    }

    .txt {
      font-size: 16px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #999999;
    }

    padding-bottom: 20px;
  }

  .list {
    width: 1000px;
    margin: auto;

    div {
      width: 1000px;
      height: 80px;

      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      font-size: 24px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #222222;
      line-height: 32px;
      padding-left: 80px;
      padding-right: 80px;

      span {
        text-align: center;

        &:first-child {
          width: 200px;
        }

        &:last-child {
          width: 200px;
        }

      }
    }

    .header {
      padding-left: 80px;
      font-size: 16px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #666666;
      background: #EFF3FF;
      line-height: 24px;
      padding-right: 80px;
    }

    .huise {
      background: #F6F6F6;
      border-radius: 6px;
      font-size: 24px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #222222;
    }
  }

  .footer-text {

    border-top: 1px solid #E2E2E2;
    width: 1000px;
    margin: auto;
    margin-top: 80px;
    padding: 80px 0;

    h3 {
      width: 1000px;
      text-align: center;
      font-size: 24px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #222222;
      line-height: 32px;
      margin-bottom: 20px;
    }

    p {
      text-align: center;
      width: 1000px;
      font-size: 16px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #222222;
      line-height: 24px;
    }
  }

  .footer-box {
    width: 1400px;
    height: 446px;
    background: #F6F6F6;
    border-radius: 10px;
    background: #F6F6F6;
    border-radius: 10px;
    background: #F6F6F6;
    background: #f6f6f6;
    border-radius: 10px;


    padding-top: 80px;

    .img {
      margin: 0 auto;
      width: 54px;
      height: 54px;

      margin-bottom: 30px;
    }

    h3 {
      width: 100%;
      text-align: center;
      font-size: 21px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #222222;
      margin-bottom: 10px;
    }

    p {
      margin: auto;
      text-align: center;
      font-size: 16px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #222222;
      width: 800px;
      height: 72px;
      line-height: 24px;
    }

    .bottom {
      width: 240px;
      height: 60px;

      border-radius: 4px;
      border: 2px solid #ff730b;
      line-height: 60px;
      text-align: center;
      margin: auto;
      font-size: 16px;
      margin-top: 30px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #ff730b;

      &:hover {
        cursor: pointer;
      }
    }
  }

}
</style>